/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ngx-datatable CSS
@import "node_modules/@siemens/ngx-datatable/index.css";
@import "node_modules/@siemens/ngx-datatable/themes/material.css";
@import "node_modules/@siemens/ngx-datatable/assets/icons.css";

//datepicker css
@import './app/shared/components/ng-pick-datetime/assets/style/picker.min.css';

// ngx-smart-modal
@import "node_modules/ngx-smart-modal/styles/ngx-smart-modal.css";

//ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";

//Ionic 6 patch ..not showing ion-alert
.overlay-hidden.sc-ion-alert-md-h{
  display: flex !important;
}


// Material icons
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/fonts/Material-Icons.woff2) format('woff2');
}

/* source-sans-pro - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/SourceSansPro-Regular.ttf') format('truetype');

  }

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    // -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

// Global CSS

.sc-ion-card-md-h{
    font-size: initial !important;
}

.ion-color-status-approved {
    --ion-color-base: var(--ion-color-status-approved) !important;
}
.ion-color-status-rejected {
    --ion-color-base: var(--ion-color-status-rejected) !important;
}
.ion-color-status-waitingforapproval {
    --ion-color-base: var(--ion-color-status-waitingforapproval) !important;
}
.ion-color-status-pending {
    --ion-color-base: var(--ion-color-status-pending) !important;
}

.ion-color-red {
    --ion-color-base: var(--ion-color-status-rejected) !important;
}

.isNode-new{
    // color: red !important;
    font-weight: bold;
}

.ion-color-black {
    --ion-color-base: var(--ion-color-black) !important;
}
.ion-color-orange {
    --ion-color-base: var(--ion-color-status-pending) !important;
}
.ion-color-blue {
    --ion-color-base: var(--ion-color-status-waitingforapproval) !important;
}
.ion-color-green {
    --ion-color-base: var(--ion-color-status-approved) !important;
}
.ion-color-object-status-red{
    --ion-color-base: var(--ion-color-object-status-red) !important;
}
.ion-color-object-status-orange {
    --ion-color-base: var(--ion-color-object-status-orange) !important;
}
.ion-color-object-status-green {
    --ion-color-base: var(--ion-color-object-status-green) !important;
}
// .sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button,
// .ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button{
//     --color-hover: var(--ion-color-dark-shade);
// }
// .sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s.light-shade-buttons .button,
// .ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s.light-shade-buttons .button {
//     --color-hover: var(--ion-color-contrast);
// }
// Scrollbar
::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

::-webkit-scrollbar-track {
    background: rgb(238, 238, 238);;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

::-webkit-scrollbar-thumb:hover {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

::-webkit-scrollbar-thumb:active {
    background: #888;
}


//changed by amar
ion-content{
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --background: #F8F8F8;
}



ion-header{
    background: #F8F8F8 !important;
}


.bg-quickscan-checkbox{
    background: #F8F8F8 !important;
    --background: #F8F8F8 !important;
}


.main-content-part .ngx-datatable.material,
.main-content-part ngx-datatable {
    margin-top: 56px !important;
}

// body{
//     background: #eeeeee !important;
// }


//table css
.min-table-height {
    min-height: 200px;
}
.data-table {
    border: 1px solid var(--ion-color-light-shade);
    font-size: 14px;
    margin: 38px 100px;
    margin-top: 0;
    //min-height: 200px;
}
.data-table ion-col {
    padding: 0 5px;
}

.custom-data-table ion-col {
    padding: 0 5px;
}
.table-header {
    background: var(--ion-color-secondary);
    color: #fff;
    padding: 10px;
    font-weight: 600 !important;
}

.t-header {
    background: var(--ion-color-secondary);
    color: #fff;
    padding: 10px;
    font-weight: 600 !important;
}
.table-header.non-sortable, .table-header.non-sortable label {
    cursor: default !important;
}

.custom-data-table .table-header{
    background: white;
    color: black;
}
.custom-data-table .table-header{
    background: white;
    color: black;
}

.table-header-detail {
    background: var(--ion-color-secondary);
    color: #fff;
    padding-left: 10px;
    cursor: pointer;
}

.table-header ion-col>ion-icon {
    padding-left: 5px;
}
.sticky-table-header {
    position: sticky;
    top: 44px;
    z-index: 9;
}
.treatmentStep-table-header {
    position: sticky;
    top: 44px;
    z-index: 9;
}

.treatmentStep-table{
    position: sticky;
    top: -10px;
    z-index: 9;
}
.clickable, .clickable label, .editable .table-header, .editable label, .editable ion-row {
    cursor: pointer;
}


.table-body ion-row {
    padding: 10px;
    border-bottom: 1px solid var(--ion-color-light-shade);
}
.t-body{
    padding: 10px;
    border-bottom: 1px solid var(--ion-color-light-shade);
}
.t-row:hover{
    background: var(--ion-color-light-shade);
}
.table-body ion-row:hover {
    background: var(--ion-color-light-shade);
}

.table-body-detail {
    background: white;
    border-bottom: 1px solid var(--ion-color-light-shade);
    padding-left: 10px;
}

.table-body ion-col, .table-body-detail {
    overflow: hidden;
}
//

.flex{
    display: flex;
}
img {
    max-width: 100%;
}
.ion-no-padding{
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.card-row{
    background: var(--ion-color-light);
}
ion-item > ion-icon {
    margin-right: 14px !important;
}
.material-icons {
    font-size: 28px;
}

.margin-right {
    margin-right: 10px;
}
// ion-button {
//     --background-hover: transparent !important;
// }







.datatable-body-cell-label,
ion-card-subtitle,
ion-label,
.datatable-header-cell-label {
    font-size: 16px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.datatable-body-cell-label > div,
.datatable-header-cell-label > div{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.datatable-body-cell.checkbox-cell .datatable-body-cell-label {
    overflow: initial;
}
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #eeeeee !important;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #eeeeee !important;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap{
    overflow: initial;
}
form ion-label,
ion-label.label {
    color: var(--ion-color-dark) !important;
}
datatable-body-row:hover {
    cursor: pointer;
}

.ngx-datatable.material.messageScreen datatable-body-row:hover,
.ngx-datatable.material.tableList datatable-body-row:hover {
    cursor: default !important;
}

.alert-radio-label.sc-ion-alert-md {
    text-transform: none !important;
}
.sc-ion-alert-md button[disabled]{
        opacity: 0.4 !important;
}
.ngx-datatable.material .datatable-footer .page-count {
    display: none !important;
}

datatable-footer .datatable-footer-inner{
    margin-left: -7px !important;
}

.alert-title {
    font-size: 16px !important;
}

.alert-button.sc-ion-alert-md {
    text-transform: none;
}

.ngx-modal-selectLocale,
.ngx-modal-selectUrgency,
.ngx-modal-selectRent{
    opacity: 0;
}


// .invalid-feedback {
//     color: var(--ion-color-danger);
//     font-size: 12px;
//     margin-top: 14px;
//     text-align: center;
//     width: 100%;
// }

.lang-select ion-select {
    max-width: 100%;
    width: 100%;
}

.lang-select ion-label {
    display: none !important;
}

ion-button {
    --border-radius: 7.5px;
    text-transform: none;
}

ion-input {
    padding: 7px;
}

ion-buttons {
    margin: 0;
}

ion-button.menu-button {
    min-width: auto !important;
}

.rowInput {
    width: calc(100% - 18px);
    padding: 4px 7px;
    margin: 0;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 8px;
}

.rowInput:focus {
    box-shadow: none;
    outline: none;
}

.inputCell .datatable-body-cell-label {
    margin-top: -2px;
}
.inputCell {
    padding: 0 !important;
}
.footer-md:before {
    background-image: none !important;
}

.brandImages ion-col {
    text-align: center;
}

.maintenance ion-card:hover {
    cursor: pointer;
}

//ng-select

.ng-select.ng-invalid.ng-touched .ng-select-container:after {
    border-color:#f04141 !important
}
.ng-select.ng-invalid.ng-untouched.ngselect-invalid .ng-select-container:after {
    border-color:#f04141 !important
}
.ng-select .ng-select-focused.ng-valid.ng-select-container:after {
    border-color:#10dc60 !important;
}
.ng-select.ng-valid.ng-touched .ng-select-container:after {
    border-color:#10dc60 !important
}
// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
//     background-color: var(--ion-color-primary) !important;
// }
.ng-clear-wrapper.ng-star-inserted{
    display:none !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder{
    color: rgba(0,0,0,0.54) !important;
}
.ng-select .ng-arrow-wrapper .ng-arrow{
    color: rgba(0,0,0,0.54) !important;
}
.ng-select.ng-select-opened.ng-invalid{
    border-color: red !important;
}
.ng-select{
    padding-bottom: 0px;
}
.ng-select .ng-select-container:after {
    border-bottom: 1.6px solid #f1f1f1 !important;
}
.ng-dropdown-panel.ng-select-bottom{
    top: calc(100% - 3px);
}
.ng-placeholder{
    top: unset !important;
    // margin-top:7px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
// .ng-select .ng-arrow-wrapper{
//     top: 5px;
// }

.ng-dropdown-panel.ng-select-multiple .ng-dropdown-panel-items .ng-option{
    height: 3em;
    display: flex;
    align-items: center;
}

.ng-dropdown-panel.ng-select-multiple .ng-dropdown-panel-items .ng-option .ng-option-label{
    font-weight: 500 !important;
}
// .ng-dropdown-panel.ng-select-multiple .ng-dropdown-panel-items .ng-option .ng-option-label:hover{
//     font-weight: 500;
// }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value-label{
    font-size: 14px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    margin: 5px 0 0px 8px;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container{
    margin-bottom: 7px;
    padding-top: 0px;
}

.ng-select {
    .ng-select-container {
        &:after {
            transition: none !important;
        }
        &.ng-appearance-outline {
            &:after {
                transition: none !important;
            }
        }
    }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{
    position: relative;
    top: -1px;
}

.ng-select.ng-select-multiple .ng-select-container {
    min-height: 47px;
    outline: none;
    border: none;
    box-shadow: inset 0px -3px 0 0 #dfdfdf;
}
ion-card {
    --background: var(--ion-color-light) !important;
    box-shadow: none !important;
    padding: 20px;
}

ion-card:focus {
    outline: none;
}

ion-progress-bar {
    display: inline-block !important;
    height: 20px !important;
}

.datatable-checkbox {
    margin-top: -10px;
}

.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-focus.label-stacked.sc-ion-label-md-h,
.item-has-focus .label-stacked.sc-ion-label-md-h {
    color: var(--ion-color-dark, #3880ff);
}
.label-floating.sc-ion-label-md-h{
    transform: translate3d(0, 30px, 0);
}
ion-popover.searchPopOver {
    --width: 350px !important;
    position: absolute;
    left: -13px;

}

ion-popover.searchPopOver.helptext {
    white-space: pre-line;

}

ion-popover.popPopOver {
    --width: 200px !important;
}

ion-popover.DomainpopPopOver {
    --width: 200px !important;
    // top: -20%;
     right: 19%;

}

.mat-menu-content{
    width: 200px !important;
}




.popover-content.sc-ion-popover-ios{
    border-radius: 0px !important
}

.popover-viewport.sc-ion-popover-ios {
    --ion-safe-area-top: 0px;
    --ion-safe-area-right: 15px !important;
    --ion-safe-area-bottom: 0px;
    --ion-safe-area-left: 0px;
}



.toast{

    // --max-width: auto !important;
    max-width: 350px;
    // min-width: 200px;
    // --min-width: auto !important;
    left: 50%;

    text-align: center !important;
    transform: translateX(-50%);
    --background: orange !important
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    font-size: 14px;
    background: rgb(238, 238, 238);
}

ngx-datatable,
.ngx-datatable.material {
    box-shadow: none;
    background: rgb(238, 238, 238);
    border: none;
    border-bottom: 6px solid #dfdfdf !important;
}
.ngx-datatable.material.messageScreen .datatable-body {
    background: #F8F8F8 !important;
}

.ngx-datatable.material .empty-row {
    margin-top: 15px !important;
    text-align: center;
    color: var(--ion-color-dark);
}

.dashboard .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    padding-left: 2px !important;
}

.dashboard .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding-left: 2px !important;
    padding-left: 2px !important;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 2px;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap{
    overflow: initial;
    white-space: nowrap;
    text-overflow: ellipsis;
}
ion-select {
    width: 100%;
    min-width: 100%;
    padding-left: 8px !important;
}

.tree-button {
    min-width: auto;
    --border-radius: 50%;
    overflow: hidden;
    height: 36px !important;
}
.tree-button.ionic-treee-button {
    height: auto !important;
}

.tree-button ion-icon,
.tree-button+ion-icon {
    font-size: 16px !important;
}
.tree-button.ionic-treee-button ion-icon,
.tree-button.ionic-treee-button+ion-icon {
    font-size: 22px !important;
}
.nsm-dialog-btn-close{
    display: none;
}
.dashboard-search-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0 !important;
    display: flex;

   .nsm-content {
        padding: 0px;
        width: 100%;
    }
    .ngx-modal-objectsFilter .nsm-content{
        padding: 1rem;
    }
}
// secondary top-bar
.secondary-topbar {
    background: var(--ion-color-secondary);
    min-height: 56px;
    max-height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // position: fixed;
    width: 100%;
    z-index: 1;
}

//toggleLawText

.lawtextClass{
    display: flex;
    align-items: center;
}

.secondary-topbar ion-icon {
    color: var(--ion-color-light);
}

.secondary-topbar ion-text {
    font-weight: 500;
    font-size: 18px;
}

.paging-info {
    margin-right: 20px;
    font-size: 12px;
    font-weight: 400;
}

/* For Firefox */
input[type='number'] {
    -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

p-treenode {
    display: block;
}

.ui-tree {
    width: 18em;
}
.ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    width: 90% !important;
    cursor: pointer;
}
.ui-tree .ui-treenode-selectable.ui-treenode-content {
    cursor: pointer;
}

.ui-tree .ui-tree-container {
    height: 100%;
    margin: 0;
    overflow: hidden !important;
    padding: .25em;
    white-space: nowrap;
}

.ui-tree-empty-message {
    padding: .25em;
    padding: 18px;
}

.ui-tree .ui-treenode-children {
    margin: 0;
    padding: 0 0 0 1em;
}

.ui-tree .ui-treenode {
    background-attachment: scroll;
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat-y;
    list-style: none outside none;
    margin: 0;
    padding: .125em 0 0 0;
}

.ui-tree .ui-treenode-droppoint {
    height: 4px;
    list-style-type: none;
}

.ui-tree .ui-treenode-droppoint-active {
    border: 0 none;
}

.ui-tree .ui-tree-toggler {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-treenode-icon {
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-treenode-label {
    display: inline-block;
    padding: 0 .25em;
    vertical-align: middle;
}

.ui-tree .ui-treenode-label.ui-state-hover,
.ui-tree .ui-treenode-label.ui-state-highlight {
    font-weight: normal;
    border: 0 none;
}

.ui-tree .ui-treenode.ui-treenode-leaf>.ui-treenode-content>.ui-tree-toggler {
    visibility: hidden;
}

.ui-tree .ui-chkbox-box {
    cursor: pointer;
}

.ui-tree .ui-chkbox {
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-chkbox .ui-chkbox-icon {
    margin-left: 1px;
}

.ui-tree .ui-tree-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
}

.ui-tree .ui-tree-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
    width: 100%;
}

.ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
    position: absolute;
    top: .8em;
    right: 1em;
}

/** Fluid **/
.ui-fluid .ui-tree {
    width: 100%;
}

/** Horizontal Tree **/
.ui-tree-horizontal {
    width: auto;
    padding: .5em 0;
    overflow: auto;
}

.ui-tree.ui-tree-horizontal table,
.ui-tree.ui-tree-horizontal tr,
.ui-tree.ui-tree-horizontal td {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

.ui-tree.ui-tree-horizontal .ui-tree-toggler {
    vertical-align: middle;
    margin: 0;
}

.ui-tree-horizontal .ui-treenode-content {
    font-weight: normal;
    padding: 0.4em 1em 0.4em 0.2em;
}

.ui-tree.ui-tree-horizontal .ui-tree-node-label {
    margin: 0;
}

.ui-tree-horizontal .ui-treenode-parent .ui-treenode-content {
    font-weight: normal;
    white-space: nowrap;
}

.ui-tree.ui-tree-horizontal .ui-treenode {
    padding: .25em 2.5em;
}

.ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-leaf,
.ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-collapsed {
    padding-right: 0;
}

.ui-tree.ui-tree-horizontal .ui-treenode-children {
    padding: 0;
    margin: 0;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector {
    width: 1px;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-table {
    height: 100%;
    width: 1px;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-line {
    width: 1px;
}

.ui-tree.ui-tree-horizontal table {
    height: 0;
}

.ui-tree.ui-tree-horizontal .ui-chkbox {
    vertical-align: bottom;
    margin-right: .25em;
}

/** Loading **/
.ui-tree.ui-tree-loading {
    position: relative;
    min-height: 4em;
}

.ui-tree .ui-tree-loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.ui-tree .ui-tree-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.ui-tree .ui-tree-loading-content .ui-tree-loading-icon {
    font-size: 2em;
}

body .ui-tree {
    border: none !important;
    background-color: #eeeeee;
    color: #333333;
    padding: 0 !important;
    width: 100% !important;
    margin-left: -18px;
}

body .ui-tree .ui-tree-container {
    padding: 0;
    margin: 0;
}

body .ui-tree .ui-tree-container .ui-treenode {
    padding: 0.143em 0;
    padding-left: 0.15em !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
    padding: 0;
    border: 1px solid transparent;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content:focus {
    outline: none !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    vertical-align: middle;
    display: inline-block;
    float: none;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    padding: 0.286em;
}
.ngx-modal-activityListFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-folderEntryFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-addNewFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-approveUploadsFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-userActivityFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-FoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    width: 77% !important;
    cursor: pointer;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin: 0 0.5em 0 0;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable.ui-treenode-content-selected{
    // outline: solid lightgray !important;
    font-weight: bold !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
    margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label.ui-state-highlight {
    cursor: default;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
    background-color: #eaeaea;
    color: #333333;
    cursor: pointer;
}

// body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label:hover {
//     background-color:lightgray !important;
// }

.no-ripple{
    --background-hover: transparent !important
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content>span {
    line-height: inherit;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
    background-color: #eaeaea;
    color: #333333;
}

body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
    background-color: #007ad9;
}

body .ui-tree.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
    color: #007ad9;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
    background-color: inherit;
    color: inherit;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
    background-color: #eaeaea;
    color: #333333;
}

body .ui-tree .ui-tree-filter-container .ui-tree-filter {
    width: 100%;
    padding-right: 2em;
}

body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.8em;
    color: #007ad9;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content>span{
    color: rgb(0, 0, 0);
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label {
    background-color: transparent;
    color: rgb(0, 0, 0);
}
.ui-treenode-content{
    display: inline-block !important;
}

.object-folders-tree .ui-tree{
    width: 100% !important;
}
.activitylist-object-folders-tree{
    width: 100% !important;
}
.activitylist-object-folders-tree .ui-tree {
    width: 100% !important;
}
ion-segment {
    justify-content: start !important;
}
.ion-segment label{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.link {
    font-size: 16px;
    text-decoration: underline;
    color: var(--ion-color-dark) !important;
}

.domainImage {
    display: block;
    max-height: 100px;
    margin: 0 auto;
}

ion-item {
    --inner-padding-top: 0px !important;
}
ion-header ion-chip ion-label{
    overflow: inherit !important;
}
textarea {
    // min-height: 100px !important;
    height: 79px;
    border: none;
    padding: 8px !important;
    resize: vertical;
    // overflow: hidden;

}
textarea:focus{
    border: none;
    outline:none;
}

.textarea-lg textarea{
    min-height: 250px !important;
}

ion-input, textarea {
    //--padding-start: 8px !important;
    --padding-end: 8px !important;
    font-size: 14px !important;
    color: #282828 !important;
}

ion-segment-button {
    text-transform: none;
    margin: 0;
}

.sc-ion-segment-md-h {
    background: var(--ion-color-tertiary) !important;
    display: flex;
    align-items: baseline;
}

ion-segment:hover {
    --background-hover: #e3e3e3;
}

// dashboard pie chart
.dash-chart {
    overflow: hidden;
    padding: 0;
}

.dash-chart-topbar {
    overflow: hidden;
    padding: 0;
}

.dash-chart .legend-title {
    text-align: left;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
}
.pie-chart-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
ngx-charts-pie-chart {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.dash-chart .ngx-charts {
    left: -30px;
    position: relative;
    top: 30px;
    transform: scale(.8);
}
.pie-label {
    font-size: 16px !important;
}
.dash-chart .chart-legend {
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 180px;
    max-width: 180px;
}
.dash-chart .chart-legend > div{
    width: 100% !important;
}
// Ion Modal
.select-modal {
    .searchbar-input {
        box-shadow: none;
    }

    .modal-wrapper {
        position: absolute !important;
    }
}
.selectdomainmodal {
    .searchbar-input {
        box-shadow: none;
    }

    .modal-wrapper {
        position: absolute !important;
    }
}

.select-checklistmodal{
    .sc-ion-searchbar-md-h{
        padding-left: 5.5rem;
    }
    .header-md{
        margin-top: -23px;
    }
    .searchbar-input.sc-ion-searchbar-md{
        box-shadow: none;
    }
    .searchbar-input {
        box-shadow: none;
    }

    .modal-wrapper {
        position: absolute !important;
    }
}

.select-monitoringmodal{
    .sc-ion-searchbar-md-h{
        padding-left: 5.5rem;
    }
    .header-md{
        margin-top: -23px;
    }
    .searchbar-input.sc-ion-searchbar-md{
        box-shadow: none;
    }
    .searchbar-input {
        box-shadow: none;
    }

    .modal-wrapper {
        position: absolute !important;
    }
}

.select-domainmodal{
    .sc-ion-searchbar-md-h{
        padding-left: 5.5rem;
    }
    .header-md{
        margin-top: -23px;
    }
    .searchbar-input.sc-ion-searchbar-md{
        box-shadow: none;
    }
    .searchbar-input {
        box-shadow: none;
    }

    .modal-wrapper {
        position: absolute !important;
    }
}

ion-modal {
    --border-radius: 4px!important;
}
ion-menu-button{
    margin-left: 4px !important;
}
.secondary-topbar > ion-text:first-child{
    margin-left: 18px !important;
}
.secondary-topbar > div:first-child{
    margin-left: 18px !important;
}
// button in a ionic-selectable
.alert-button.sc-ion-alert-md {
    border-radius: 2px;
    margin-left: 0;
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background-color: transparent;
    color: var(--ion-color-primary, #3880ff);
    font-weight: 500;
    text-align: end;
    text-transform: uppercase;
    overflow: hidden;
}

.rating-alert-button.sc-ion-alert-md {
    border-radius: 5px;
    border-width: 1px;
    border-color: #9dc7b7;
    margin-left: 0;
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative;
    background-color: #9dc7b7;
    color: white;
    font-weight: 500;
    text-align: end;
    text-transform: uppercase;
    overflow: hidden;
}

.alert-head.sc-ion-alert-md{
    text-align: center;
}

.alert-button-group.sc-ion-alert-md{
    justify-content: center;
}

.rating-alert-button.sc-ion-alert-md,
.alert-button.sc-ion-alert-md,
.alert-checkbox.sc-ion-alert-md,
.alert-input.sc-ion-alert-md,
.alert-radio.sc-ion-alert-md {
    outline: none;
}
.rating-alert-button-inner.sc-ion-alert-md,
.alert-button-inner.sc-ion-alert-md {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.alert-button-inner.sc-ion-alert-md,
.alert-tappable.sc-ion-alert-md {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.alert-button-inner.sc-ion-alert-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
}

.alert-button.sc-ion-alert-md:disabled {
    opacity: .6;
}
.ionic-selectable-modal{
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    ion-content{
        --background: white !important;
    }

}

.ionic-selectable-item{
    ion-icon{
        padding-left: 23px !important;
    }
}

// datepicker
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background-color: var(--ion-color-primary) !important;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
    color: var(--ion-color-primary) !important;
}

.domainChangeSelect .ionic-selectable-icon {
    position: absolute;
    right: 4px;
}

// user
datatable-body-cell.inlineListing {
    padding: 0 !important;
}

.datatable-checkbox input[type='checkbox']:checked:before {
    border-color: var(--ion-color-primary) !important;
}

body .ui-widget {
    font-size: 16px !important;

}

.split-pane-md.split-pane-visible>.split-pane-side {
    max-width: 20% !important;
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-message.sc-ion-alert-md::-webkit-scrollbar,
.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-message.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.alert-message.sc-ion-alert-md{
    text-align: center;
}

.flag-icon {
    position: absolute;
    left: 62px;
    bottom: 17px;
}

.customLocaleSelect .alert-radio-label {
    padding-inline-start: 72px;
    padding-left: 73px !important;
}
.ngx-datatable.material.messageScreen .datatable-body .datatable-body-row .datatable-body-cell{
    padding: 0 !important;
}

.messageScreen ion-card {
    background: rgb(238, 238, 238) !important;
    margin-bottom: 30px !important;
    border-bottom: 6px solid #dfdfdf;
    margin-right: 20px !important
}
ion-grid{
    padding: 7px !important ;
}
.ngx-datatable.material.messageScreen .datatable-row-wrapper:not(:last-child) .datatable-body-cell {
    padding-bottom: 0 !important;
}

.data-table .datatable-body {
    height: calc(100vh - 346px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.domainPageHeight .datatable-body {
  height: auto !important;
}

.domainuser-data-table .datatable-body{
    height: calc(100vh - 406px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}


.documentLibrary-data-table .datatable-body {
    height: calc(100vh - 300px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}
.documentLibrary-data-table {
     height: calc(100vh - 530px)
    // width: 80%;
}

.messageScreen.data-table .datatable-body {
    height: calc(100vh - 297px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.Objectdata-table  .datatable-body{
    height: calc(100vh - 280px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}

ion-menu {
    --ion-background-color:#5d445f;
    ion-list {
        background: transparent;
    }
}

.login-info > ion-row {
    padding: 10px;
}
.folder-info>ion-row{
    padding: 10px;
}
.object-info .object-info-row{
    padding: 10px;
}
.notification-info .notification-info-row{
    padding: 10px;
}
.file-info .file-info-row {
    margin: 10px 0 -2px 0;
}
.folder-entry-info-row {
    margin-bottom: 20px;
}
.object-thumbnail-column{
    margin: 0;
    padding: 0 !important;
    text-align: center !important;
    padding: 5px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    .datatable-body-cell-label {
        display: flex;
        align-self: center;
    }
}

.ngx-modal-objectsFilter.nsm-dialog,
.ngx-support-model.nsm-dialog,
.ngx-copyFolder-model.nsm-dialog,
.ngx-copyObjectType-model.nsm-dialog,
.ngx-privacy-model.nsm-dialog,
.ngx-modal-confirmPassword.nsm-dialog,
.ngx-modal-confirmProfilePassword.nsm-dialog,
.ngx-modal-confirmResetCountPassword.nsm-dialog,
.ngx-modal-activityListFilter.nsm-dialog,
.ngx-copyObjectTypeFolder-model.nsm-dialog,
.ngx-modal-approveUploadsFilter.nsm-dialog,
.ngx-modal-usersFilter.nsm-dialog,
.ngx-modal-activityListFoldersFilter.nsm-dialog,
.ngx-modal-objectTypeFoldersTree.nsm-dialog,
.ngx-modal-folderEntryFoldersTree.nsm-dialog,
.ngx-modal-addNewFoldersTree.nsm-dialog,
.ngx-modal-objectTemplateFoldersTree.nsm-dialog,
.ngx-modal-approveUploadsFoldersFilter.nsm-dialog,
.ngx-modal-userActivityFoldersFilter.nsm-dialog,
.ngx-modal-templateFolders.nsm-dialog,
.ngx-modal-FoldersFilter.nsm-dialog,
.ngx-modal-userInviteModal.nsm-dialog {
    top: 50%;
    transform: translateY(-50%);
}
.ngx-modal-activityListFoldersFilter .nsm-content,
.ngx-modal-objectTypeFoldersTree .nsm-content,
.ngx-modal-folderEntryFoldersTree .nsm-content,
.ngx-modal-addNewFoldersTree .nsm-content,
.ngx-modal-objectTemplateFoldersTree .nsm-content,
.ngx-modal-userActivityFoldersFilter .nsm-content,
.ngx-modal-approveUploadsFoldersFilter .nsm-content {
    max-height: 546px !important;
    overflow: hidden;
    padding: 0 !important;
}
.ngx-modal-activityListFoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-objectTypeFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-folderEntryFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-objectTemplateFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-userActivityFoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-approveUploadsFoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll {
    overflow-y: scroll;
    margin: 0.5rem 1rem 0.5rem 1rem;
    max-height: 416px !important;
}
.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll{
     max-height: 368px !important;
}
.ngx-modal-activityListFoldersFilter .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-objectTypeFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-folderEntryFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-objectTemplateFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-userActivityFoldersFilter .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-approveUploadsFoldersFilter .nsm-content ion-grid ion-row.ion-row-fixed {
    padding: 0rem 1rem 1rem 0;
}
.ngx-modal-userActivityFoldersFilter p-tree,
.ngx-modal-activityListFoldersFilter p-tree,
.ngx-modal-objectTypeFoldersTree p-tree,
.ngx-modal-folderEntryFoldersTree p-tree,
.ngx-modal-addNewFoldersTree p-tree,
.ngx-modal-objectTemplateFoldersTree p-tree,
.ngx-modal-approveUploadsFoldersFilter p-tree {
    max-width: 100%;
    width: 100%;
}
.ngx-modal-activityListFoldersFilter p-tree .ui-chkbox,
.ngx-modal-objectTypeFoldersTree p-tree .ui-chkbox,
.ngx-modal-folderEntryFoldersTree p-tree .ui-chkbox,
.ngx-modal-addNewFoldersTree p-tree .ui-chkbox,
.ngx-modal-objectTemplateFoldersTree p-tree .ui-chkbox,
.ngx-modal-userActivityFoldersFilter p-tree .ui-chkbox,
.ngx-modal-approveUploadsFoldersFilter p-tree .ui-chkbox {
    position: relative;
    top: 1px;
}
.ngx-modal-activityListFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-userActivityFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-approveUploadsFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree p-tree .ui-treenode-label,
.ngx-modal-folderEntryFoldersTree p-tree .ui-treenode-label,
.ngx-modal-addNewFoldersTree p-tree .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree p-tree .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree p-tree ion-text,
.ngx-modal-folderEntryFoldersTree p-tree ion-text,
.ngx-modal-addNewFoldersTree p-tree ion-text,
.ngx-modal-activityListFoldersFilter p-tree ion-text,
.ngx-modal-objectTemplateFoldersTree p-tree ion-text,
.ngx-modal-userActivityFoldersFilter p-tree ion-text,
.ngx-modal-approveUploadsFoldersFilter p-tree ion-text,
.ngx-modal-FoldersFilter p-tree ion-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ngx-modal-FoldersFilter p-tree .ui-treenode-label,
.ngx-modal-FoldersFilter p-tree ion-text{
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.ngx-modal-FoldersFilter p-tree{
    max-width: 100%;
    width: 100%;
}

.ngx-modal-FoldersFilter .nsm-content {
    max-height: 546px !important;
    overflow: hidden;
    padding: 0 !important;
}

.ngx-modal-userInviteModal .nsm-content{
    max-height: 546px !important;
    overflow: hidden;
    padding: 0 !important;

    --background: #eeeeee;
    background: #eeeeee;
}

.ngx-modal-userInviteModal .nsm-content ion-grid ion-row.ion-row-scroll{
    overflow-y: scroll;
    margin: 0.5rem 1rem 0.5rem 1rem;
    max-height: 416px !important;
}

.ngx-modal-FoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll{
    overflow-y: scroll;
    margin: 0.5rem 1rem 0.5rem 1rem;
    max-height: 416px !important;
}

.ngx-modal-ModalBgColor .nsm-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
     --background: #eeeeee;
     background: #eeeeee;

}


.ratingmodal-bgColor .nsm-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
     --background: #5d445f;
     background: #5d445f;
     border-radius: 0px !important;
     width: 504px;

}

.ratingmodal-bgColor .nsm-body{
    border-bottom: solid 5px #48354a;
}


.nsm-content{
        border-radius: 4px !important;
    }

.ngx-privacy-model.nsm-dialog {
     max-width: 100% !important;
}
.ngx-privacy-model .nsm-content {
    max-height: 546px !important;
    overflow-y: scroll;
}
.note-editing-area {
    min-height: 300px !important;
}

.editor-label {
    font-size: 16px;
    margin-bottom: 14px !important;
}

.note-editor {
    width: 100% !important;
}

.ngx-summernote-view {
    color: black;
}
.ngx-summernote-view img{
    display: inline-block;
    max-width: 100%;
    width: auto;
}
.native-textarea[disabled].sc-ion-textarea-md {
    opacity: 0.8;
}
.item-interactive-disabled.sc-ion-label-md-h,
.item-interactive-disabled .sc-ion-label-md-h {
    cursor: default;
    opacity: 1 !important;
    pointer-events: none;
}
.native-input[disabled].sc-ion-input-md{
    opacity: 0.8;
}
.item-interactive-disabled.item-select-disabled {
    opacity: 0.6 !important;
}
.progress-bar-column{
    position: relative;
}

.file-list-column {
    .datatable-body-cell-label {
        display: flex;
        align-items: center;
        align-self: center;
    }
}
.alert-wrapper.sc-ion-alert-md{
    min-width: 500px !important;
}
.urgency-select-interface .alert-wrapper.sc-ion-alert-md,
.custom-urgency-select-interface .alert-wrapper.sc-ion-alert-md {
    min-width: 490px !important;
    width: 100%;
}
.status-select-interface .alert-wrapper {
    width: 100%;
}
.overlay.nsm-overlay-open {
    background-color: transparent;
    overflow: hidden;
}
.ngx-datatable.material .datatable-header{
    border-bottom: none;
}
.ngx-datatable.material.border-header .datatable-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-footer {
    border-top: none;
}
.ngx-datatable.material.border-footer .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.custom-loading-spinner ~ .loading-wrapper{
    display: none !important;
}
.custom-alert ~ .alert-wrapper,
.custom-alert ~ ion-backdrop:not(.custom-alert-backdrop) {
    display: none !important;
}
ion-item.ion-touched .wysiwyg-editor.ng-invalid + .note-editor{
    border-bottom: 2px solid var(--ion-color-danger);
}

// .wysiwyg-editor.ng-invalid.ng-dirty.ng-touched + .note-editor{
//     border-bottom: 2px solid var(--ion-color-danger);
// }

.wysiwyg-editor {
    border-radius: 0 !important;
}

.note-editor.note-frame, .note-editor.note-airframe {
    border: none !important;
}

// disable modal backdrop
ion-backdrop {
    opacity: 0 !important;
    background: transparent !important;
    // display: none;
}
ion-item.no-padding{
    --padding-end: 0px;
    --inner-padding-end: 0px;
}
// remove resizer from ngx-summernote
.note-editor.note-frame .note-statusbar .note-resizebar {
    display: none !important;
}
// border-outline
.border-outline{
    border: 1px solid rgb(197, 201, 204);
}
.report-overview .users .data-table .datatable-body {
    height: calc(100vh - 348px) !important;
}

//object-file
.report-objectfile .objectfile .data-table .datatable-body {
    height: calc(100vh - 390px) !important;
}


.report-overview .objects .data-table .datatable-body  {
    height: calc(100vh - 272px) !important;
}
.report-overview .companies .data-table .datatable-body  {
    height: calc(100vh - 272px) !important;
}
.report-overview .objectGroups .data-table .datatable-body  {
    height: calc(100vh - 272px) !important;
}

.popover-select-interface .popover-wrapper .popover-content {
    transform: scale(1) translate(-50%, -50%) !important;
    position: absolute !important;
    left: 65% !important;
    top: 40% !important;
    width: 464px;
    max-width: 464px;
    max-height: 618px;
}
.ionic-selectable-value-item:not(:last-child) {
    margin-bottom: 8px;
}
.secondary-topbar > div{
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   color: #fff;
}
.ngx-datatable .datatable-footer .datatable-pager {
    flex: 1 1 60% !important;
}
.tree-expand-button{
    margin-top: -9px;
    margin-left: -4px;
}
.tree-expand-button ion-button i{
    font-size: 24px;
    color: #848484;
    position: relative;
    color: #848484 !important;
}
.normalColor{
    color: #686868;
}
.primaryColor{
    color: var(--ion-color-primary);
}
.padding-left-6{
    padding-left: .6rem !important;
}
.urgency-header-column{
    padding: 0 !important;
    padding-left: .25rem !important;
}
.urgency-row-icon{
    padding: 0 !important;
    padding-left: .25rem !important;
}
.urgency-row-icon .datatable-body-cell-label {
    left: 2px;
    top: 16px;
    position: relative;
}
.fa-icon-button{
    height: 48px !important;
    width: 48px !important;
    border-radius: 50% !important;
    overflow: auto !important;
}
.datatable-row-detail{
    overflow: hidden;
}
.custom-select-interface,
.custom-urgency-select-interface {
    width: calc(100% - 270px);
    position: absolute;
    left: 270px;
}
.custom-select-interface .alert-wrapper,
.custom-urgency-select-interface .alert-wrapper{
    position: relative;
}
.object-status-green {
    border: 1px solid var(--ion-color-object-status-green);
}

.object-status-orange {
    border: 1px solid var(--ion-color-object-status-orange);
}

.object-status-red {
    border: 1px solid var(--ion-color-object-status-red);
}
.split-pane-md.split-pane-visible>.split-pane-side {
    max-width: 270px !important;
}
.locale-select.item-interactive-disabled.item-select-disabled{
    opacity: 1 !important;
}
.locale-select ion-select{
    opacity: 1 !important;
}
.locale-select.item-interactive-disabled.sc-ion-label-md-h,
.locale-select.item-interactive-disabled .sc-ion-label-md-h{
    opacity: 1 !important;
}
.ngx-datatable.material .datatable-body .datatable-row-detail{
    padding: 0;
}
.ui-state-default.tree-checkbox-disabled{
    opacity: 0.3;
    cursor: default;
}
.object-files-tree-row p-tree{
    width: 100%;
}
.activitylist-object-folders-tree.ui-tree{
    margin-left: -18px;
}
.objectfiles-folders-tree.ui-tree {
    margin-left: -19px;
}
.menu-items ion-badge {
    --background: var(--ion-color-danger);
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    border-radius: 50%;
    font-weight: 400;
}
.bold{
    font-weight: 600 !important;
}
.normal {
    font-weight: 400 !important;
}
.priority-icon{
    padding: 11px 0 0 7px !important;
}
.table-loading{
    z-index: 9;
    animation-duration: unset !important;
    left: 50%;
    top: 120px;
    transform: translateX(-50%);
    position: absolute;
    color: var(--ion-color-primary) !important;
}
.custom-empty-message{
    margin-top: 65px !important;
    text-align: center;
    display: block;
    color: var(--ion-color-dark);
    height: 50px;
    padding: .5rem 1.2rem;
    border-top: 0;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.tree-legend{
    min-width: 180px;
    max-width: 180px;
    position: relative;
    top: 11px;
    margin-bottom: 0px;
}
.tree-legend-title{
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}
.tree-legend-labels{
    padding: 4px;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 3px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.05);

    .tree-legend-label{
        display: flex;
        margin: 5px;
    }

    .tree-legend-label-color{
        height: 15px;
        width: 15px;
        margin-right: 5px;
        border-radius: 3px;
    }
    .tree-legend-label-text {
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.object-detail-folders {
    display: flex;
    flex-direction: row;
}

.modal-force-close{
    .alert-wrapper{
    display: none;
    }
}
.ionic-selectable[disabled="true"]{
    pointer-events: none;
}
.master-domain-select-item[disabled="true"] {
    opacity: 0.4 !important;
}
.domainSelect .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group{
    background-color: transparent !important;
}

.custom-date-class {
    .owl-dt-calendar-cell-disabled+.owl-dt-calendar-cell {
        cursor: default;

        .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
            color: rgba(0, 0, 0, .4);
        }
    }

    .owl-dt-calendar-cell-disabled+.owl-dt-calendar-cell:hover {
        .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
            background-color: transparent !important;
        }
    }
}

.editNameClass{
    .native-input.sc-ion-input-md{
        position:  relative !important;
    top: 13px !important;
    }

}


//ngx-charts-bar-horizontal-stacked
.horizontal-chart{ svg:not(:root) {
    overflow: visible !important;
}
}

.monitoring-dash-chart .ngx-charts {
    left: 95%;
    position: relative;
    transform: scale(2.6);
}


.monitoring-dash-chart .chart-legend {
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 180px;
    max-width: 180px;
}
.monitoring-dash-chart .chart-legend > div{
    width: 100% !important;
}

.vertical-chart{ svg:not(:root) {
    overflow: visible !important;
}
}

// .horizontal-chart{
//     .ngx-charts{
//         margin-left: 30% !important;
//         display: flex;
//     }
// }

.objectFiles{
    position: relative;
    margin-left: 5px;
}

//advanced-pie-chart
.advanced-pie-legend .legend-items-container .legend-items {
    white-space: nowrap !important;
    position: absolute !important;
    overflow: visible !important;
    left: -30% !important;
    margin-top: 40% !important;
}

 .advanced-pie-legend .legend-items-container .legend-items .legend-item {
    margin-right: 4px !important;
    display: inline-block !important;
    cursor: pointer !important;
}



.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
    font-size: 10px !important;
    opacity: 0.7 !important;
    margin-left: 11px !important;
    margin-top: -4px !important;
}

.advanced-pie-legend .total-label,
.advanced-pie-legend .total-value{
    display: none !important
}


.qr-code-canvas{
    width: 200px !important;
    height: 200px !important;
    text-align-last: center !important;
}


.otp-input {
    padding: 5px !important;
    border: 1px solid #ddd !important;
    margin-left: 19px !important;
    text-align: center !important;
    max-width: 56px !important;
    height: 40px !important;
    background: white !important;
}

.otp-container{
    margin-left: -18px !important;
    display: flex !important;
    justify-content: center;
}






// Responsive

@media only screen and (max-width: 1500px) {
    .res-buttons{
        flex-direction: column;
    }

    .quickButton{
        margin-top: 12px !important;
    }


}

@media only screen and (max-width: 1650px) and (min-width: 1200px) {
        .colQuickScan{
            flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
            width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
            max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
        }


}

@media only screen and (min-width: 1200px) {
    .dash-chart .ngx-charts {
        transform: scale(.8);
    }
    .pie-label {
        font-size: 16px !important;
    }
    // .res-buttons{
    //     flex-direction: row;
    // }

}

@media only screen and (max-width: 1200px) {
    .dash-chart .ngx-charts {
        transform: scale(.8);
    }
    .pie-label {
        font-size: 16px !important;
    }
    .res-buttons{
        flex-direction: column;
    }
}

@media only screen and (max-width: 992px) {
    ion-segment{
        margin-bottom: 10px;
    }
    .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
        width: 77% !important;
        cursor: pointer;
    }
    .res-buttons{
        flex-direction: column;
    }

    .rowQuickScan{
        flex-direction: column;
    }

}

@media only screen and (max-width: 768px) {
    .custom-select-interface,
    .custom-urgency-select-interface {
        width: auto;
        position: absolute;
        left: 0;
    }
    ion-segment {
        margin-bottom: 10px;
    }
    .company-tabs {
        display: flex;
        flex-wrap: nowrap;
    }
    .company-tabs ion-segment-button {
        width: calc(100% /3);
    }
    .company-tabs ion-segment-button:last-child ion-icon{
        width: 70px;
    }
    .report-overview-tabs {
        display: flex;
        flex-wrap: nowrap;
    }
    .report-overview-tabs ion-segment-button {
        width: calc(100% /4);
    }
    .report-overview-tabs ion-segment-button:nth-child(3) ion-icon {
        width: 30px;
    }
    .report-overview-tabs ion-segment-button i.far {
        font-size: 19px !important;
    }
    .m-objects-tabs ion-segment-button {
        width: calc(100% /2);
    }
    .user-tabs ion-segment-button{
        width: 100%;
    }
    .emailexpiryclass{
        padding: 5px !important;
        margin-top: 3px !important;
    }
    .expiryEmailDays-item{
        width: 100% !important;
    }
    .user-invalid-feedback{
        position: initial !important;
    }
    .res-buttons{
        flex-direction: column;
    }
}

@media only screen and (max-width: 576px) {
    ion-segment {
        margin-bottom: 10px;
    }
    .urgency-select-interface .alert-wrapper.sc-ion-alert-md,
   .custom-urgency-select-interface .alert-wrapper.sc-ion-alert-md {
    min-width: 400px !important;
    }
    .ngx-datatable .datatable-footer .datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li{
        display: flex !important;
    }
    .object-detail-folders {
        display: flex;
        flex-direction:
         column-reverse;
    }
    .tree-legend {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 18px;
    }
    .folder-entry-folder-name {
        flex-direction: column;
        align-items: flex-start !important;

        div:nth-child(2) {
            margin-left: -10px !important;

            ion-buttons {
                top: 0 !important;
                left: 0 !important;
            }
        }

        ion-text:first-child {
            max-width: calc(100% - 34px);
        }

        ion-text:last-child {
            max-width: calc(100% - 51px);
        }
    }
    .responseClass{
        width: 5px !important;
    }
    .res-buttons{
        flex-direction: column;
    }

}

@media only screen and (max-width: 350px) {
    .paging-info{
        display: none;
    }
    .dash-chart .ngx-charts {
        padding-top: 15%;
    }
    .res-buttons{
        flex-direction: column;
    }
}

@media only screen and (max-width: 1330px){
    .need-help-container {
        padding-right: 0 !important;
    }
    .need-help {
        justify-content: start !important;
    }
}

@media only screen and (max-width: 1200px){
    .need-help-container {
        padding-right: 100px !important;
    }
    .need-help {
        justify-content: space-between !important;
    }
    .faq {
        margin-bottom: 30px;
    }
}

 @media (min-width: 768px) {
        .checkResponsive {
            margin-bottom: 10px !important;
            margin-top: 9px !important;
        }
    }
// @media (max-width: 768) {
//     .need-help-container {
//         padding-right: 100;
//     }
//     .need-help {
//         justify-content: space-between;
//     }
// }

// @media (min-width: 768) {
//     .faq {
//         margin-bottom: 30px;
//     }
// }

// @media only screen and (min-width: 576px) {
//     .need-help-container {
//         padding-right: 0;
//     }
//     .need-help {
//         justify-content: start;
//     }
// }


//Edge
@supports (-ms-ime-align: auto) {
    .alert-button-inner {
        text-align: left;
    }

    .Objectaddress{
        padding-left: 2px;
    }

    .button-native.sc-ion-segment-button-md.sc-ion-segment-button-md-s, .button-native.sc-ion-button-md{
        text-transform: none;
    }

    .datatable-checkbox input[type='checkbox']{
        top: 2px;
        border-color: var(--ion-color-primary) !important;
    }

    .sc-ion-item-md-s > ion-icon[slot=start]{
        margin-right: 14px;
    }

    .overlay.nsm-overlay-open {
        overflow: hidden;
    }
    .customLocaleSelect .alert-radio-label {
        padding-left: 73px !important;
    }
    .selectdomainmodal {
        direction: ltr;
        .list-md {
            direction: rtl;
        }
        .searchbar-input {
            box-shadow: none;
        }

        .modal-wrapper {
            position: absolute !important;
        }
    }


    .sc-ion-item-md-s > ion-icon {
        margin-right: 14px;
    }
    .searchbar-clear-icon.sc-ion-searchbar-md {
        padding-top: 10px;
    }

    .ngx-datatable .datatable-footer .datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li{
        display: flex;
    }
    .ngx-modal-FoldersFilter .nsm-content {
        max-height: 546px !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    .ngx-modal-FoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll{
        overflow-y: scroll !important;
        margin: 0.5rem 1rem 0.5rem 1rem !important;
        max-height: 416px !important;
    }
    .ngx-modal-FoldersFilter p-tree .ui-treenode-label,
    .ngx-modal-FoldersFilter p-tree ion-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
}

//Firefox
@-moz-document url-prefix() {

.datatable-checkbox {
    margin-top: -4px;
}
    .datatable-checkbox input[type='checkbox']{
        border-color: var(--ion-color-primary) !important
    }

    input[type=number] {
        -moz-appearance:textfield !important;
    }

  }


  .split-pane-md.split-pane-visible>.split-pane-side.main-menu {
    width: 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;

    ion-icon.main-menu-icon {
        margin-right: 0 !important;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -60%);
    }
}

.main-menu-icon {
    font-size: 33px;
    padding: 25px 21px;
}

.menuItem {
    outline: none !important;
    cursor: pointer;
}

.menuItem.active{
    --background: white;
    background:white;

    ion-icon{
        color: black;
    }

    ion-label{
        color: black;
    }
}

.quickScanResultIcon{
    height: 31px;
    width: 31px;
    margin-right: 25px;
}


body .ui-contextmenu {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    width: 250px;
  }
  body .ui-contextmenu .ui-menuitem-link {
    padding: 0.714em 0.857em;
    color: #333333;
    font-weight: normal;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon {
    color: #333333;
    margin-right: 0.5em;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover {
    background-color: #eaeaea;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover .ui-menuitem-icon {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff;
  }
  body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
    color: #007ad9;
  }
  body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
    color: #007ad9;
  }
  body .ui-contextmenu .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  body .ui-contextmenu .ui-menuitem {
    margin: 0;
  }
  body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
    right: 0.429em;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
    background-color: #eaeaea;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
    color: #333333;
    font-size: 21px;
    margin-bottom: -3px;
  }
  body .ui-contextmenu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
  }




//changed by amar

.button-has-icon-only.button-clear {
    height: 40px;
    width: 40px;
    --padding-top: 5px;
    --padding-end: 5px;
    --padding-bottom: 5px;
    --padding-start: 5px;
}



.icon-text {
    font-size: 24px;
    padding-right: 10px;

}

ion-text {
    color: black;
}

.ion-padding-horizontal, [padding-horizontal] {
    --padding-start: var(--ion-padding, 10px);
    --padding-end: var(--ion-padding, 10px);
    padding-left: var(--ion-padding, 10px);
    padding-right: var(--ion-padding, 10px);
}

.ion-padding-vertical, [padding-vertical] {
    --padding-top: var(--ion-padding, 10px);
    --padding-bottom: var(--ion-padding, 10px);
    padding-top: var(--ion-padding, 10px);
    padding-bottom: var(--ion-padding, 10px);
}

.ion-padding, [padding] {
    --padding: var(--ion-padding, 10px);
    padding: var(--ion-padding, 10px);
}


.ion-margin-horizontal, [margin-horizontal] {
    --margin-start: var(--ion-margin, 10px);
    --margin-end: var(--ion-margin, 10px);
    margin-left: var(--ion-margin, 10px);
    margin-right: var(--ion-margin, 10px);
}

.ion-margin-vertical, [margin-vertical] {
    --margin-top: var(--ion-margin, 10px);
    --margin-bottom: var(--ion-margin, 10px);
    margin-top: var(--ion-margin, 10px);
    margin-bottom: var(--ion-margin, 10px);
}

.ion-margin, [margin] {
    --margin: var(--ion-margin, 10px);
    margin: var(--ion-margin, 10px);
}

.ion-margin-top, [margin-top] {
    --margin-top: var(--ion-margin, 10px);
    margin-top: var(--ion-margin, 10px);
}

.ion-margin-bottom, [margin-bottom] {
    --margin-bottom: var(--ion-margin, 10px);
    margin-bottom: var(--ion-margin, 10px);
}

ion-button{
    border-radius: 7.5px !important;
    --ripple-color: transparent !important;
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    margin: 0 !important;
}

.text-white{
    color: white;
}

.text-dark{
    --color: #2b2925;
    color:#2b2925;
}

.text-red{
    --color: red;
    color:red;
}

.quickscan-lightgreen-icon{
    --color: #95ccb7;
    color: #95ccb7;

}

ion-content {
    --padding-start: 0 !important;
    --padding-end: 0 !important;;
}

.bg-white {
    --background: white;
    background: white;
}

.bg-purple {
    --background: #5d445f;
    background: #5d445f;
}

.bg-black{
    --background: #121617 !important;
    background: #121617 !important;
}

.bg-green {
    --background: #95ccb7 !important;
    background: #95ccb7 !important;

}

.bg-green-dashboard{
    --background: #95ccb7 !important;
    background: #95ccb7 !important;
}

.bg-blue {
    --background: #023970;
    background: #023970;
}

.bg-yellow {
    --background: #ffcc46;
    background: #ffcc46;
}

.bg-grey {
    --background: #eeeeee;
    background: #eeeeee;
}

.list-grey-strike{

   opacity: 0.75;
    --background:#e0e0e0;
    background:#e0e0e0;
    --background-hover: none !important;
    ion-label{
        color: rgba(43, 41, 37, 0.75) !important;
        --color: rgba(43, 41, 37, 0.75) !important;
        font-style: italic !important ;
        text-decoration: rgba(43, 41, 37, 0.75) line-through;

    }


}

.bg-light-purple {
    --background: #8b6b8e;
    background: #8b6b8e;
}

.bg-light-green {
    --background: #b4d8ca;
    background: #b4d8ca;
}

.bg-light-blue {
    --background: #9ad0d8;
    background: #9ad0d8;
}

.bg-light-yellow {
    --background: #fff3a2;
    background: #fff3a2;
}

.bg-light-grey {
    --background: #eeeeee;
    background: #eeeeee;
}

ion-button {
    height: 52px;
    border-radius: 7.5px;
}

ion-button.bg-purple:hover {
    --background: #905d95;
}

ion-button.bg-purple:active {
    --box-shadow: inset 4.4px 5.4px 0 0 rgba(0, 0, 0, 0.14);
    --background: #5d485f;
}

ion-button.bg-green:hover {
    --background: #88d4df;
}

ion-button.bg-green:active {
    --box-shadow: inset 4.4px 5.4px 0 0 rgba(0, 0, 0, 0.14);
    --background: #66a3ac;
}

ion-button.bg-blue:hover {
    --background: #255d94;
}

ion-button.bg-blue:active {
    --box-shadow: inset 4.4px 5.4px 0 0 rgba(0, 0, 0, 0.14);
    --background: #34516f;
}

ion-button.bg-yellow:hover {
    --background: #ffe786;
}

ion-button.bg-yellow:active {
    --box-shadow: inset 4.4px 5.4px 0 0 rgba(0, 0, 0, 0.14);
    --background: #ebac48;
}

.w-100 {
    width: 100%;
}

ion-item {
    --inner-border-width: 0;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    --highlight-color-valid: #dfdfdf;
    --highlight-color-invalid:  #dfdfdf;
    --background-focused: none !important;
}

ion-item:not(.item-lines-none) {
    --border-width: 0 0 3px;
    --border-color: #dfdfdf;
    --inner-border-width: 0;
}

ion-input {
    min-height: 47px;
    background-color: #ffffff;
}

.mat-step-optional {
    display: none
}

.card-col {
    margin: 15px;
}

.card-box {
    background: #eeeeee;
}

.card-content {
    padding: 40px;
}

.text-purple {
    --color: #5d445f;
    color: #5d445f;
}

.text-green {
    --color: #95ccb7;
    color: #95ccb7;

}

.text-blue {
    --color: #023970;
    color: #023970;
}

.text-yellow {
    --color: #ffcc46;
    color: #ffcc46;
}

.text-light-purple {
    --color: #8b6b8e;
    color: #8b6b8e;
}

.text-light-green {
    --color: #b4d8ca;
    color: #b4d8ca;
}

.text-light-blue {
    --color: #9ad0d8;
    color: #9ad0d8;
}

.text-light-yellow {
    --color: #fff3a2;
    color: #fff3a2;
}


.list-icon {
    padding-right: 25px;
    font-size: 31px;
}

.label-stacked.sc-ion-label-md-h{
    transform: none !important;
    margin-bottom: 10px;
    color: var(--ion-color-dark) !important;
}

.border-bottom {
    border-bottom: 6px solid #dfdfdf;
}

.border-top {
    border-top: 6px solid #dfdfdf;
}

.help-container {
    margin-top: 64px;
    height: 458px;
}

.input-icon {
    font-size:19px;
    padding: 10px;
}



.btn-icon {
    font-size: 19px;
}



.input-group {
    width: 100%;
    display: flex;
    background: #ffffff;
    align-items: center;
    // border-bottom: 3px solid #dfdfdf;
    box-shadow: inset 0px -3px 0 0 #dfdfdf;
}

.input-group.is-invalid {
  //border: solid 2px #f36d6d !important;
  border: solid 2px orange !important;
  border-bottom: 0 !important;
  box-shadow: inset 0px -3px 0 0 orange !important;
}

.input-code-group{
    align-items: center;
    width: 100%;
    display: flex;
    // border-bottom: 3px solid #dfdfdf;

}

.input-code-group.is-invalid{
    border: solid 2px orange !important;
  border-bottom: 0 !important;
  box-shadow: inset 0px -3px 0 0 orange !important;
  padding-bottom: 3px !important;
    padding-left: 0px !important;
}

.radio-group.is-invalid {
  border: solid 2px orange !important;
  border-bottom: solid 3px orange !important;
}

.input-group .input-icon[slot="start"] {
    padding-right: 0 !important;
}

.textarea-group {
    width: 100%;
    display: flex;
    background: #ffffff;
    align-items: center;
    border-bottom: 3px solid #dfdfdf;
    //box-shadow: inset 0px -3px 0 0 #dfdfdf;
}

.textarea-group.is-invalid {
    //border: solid 2px #f36d6d !important;
    border: solid 2px orange !important;
    border-bottom-width: 3px !important;
    //box-shadow: inset 0px -3px 0 0 orange !important;
  }

.input-group .input-icon[slot="end"] {
    padding-left: 0 !important;
}

.invalid-feedback {
    color: orange !important;
    font-size: 0.875rem !important;
    font-style: italic;
    margin-top: 14px;
    text-align: center;
    width: 100%;
    font-weight: 400 !important;
    font-family: Source Sans Pro !important;
}

.header {
    font-size: 38px;
    font-weight: bold;
    margin: 0;
}

.sub-header {
    font-size: 20px;
    margin: 0;
    margin-top: 10px;
}

.m-0 {
    margin: 0 !important;
}

.list-item {
    font-size: 16px;
    font-weight: 600;
}

::-webkit-input-placeholder { /* Edge */
    font-size: 14px;
    font-style: italic !important;
    color: #8e8e8c !important;
    opacity: 1 !important;
  }

  :-ms-input-placeholder { /* Internet Explorer */
    font-size: 14px;
    font-style: italic !important;
    color: #8e8e8c !important;
    opacity: 1 !important;
  }

  ::placeholder {
    font-size: 14px;
    font-style: italic !important;
    color: #8e8e8c !important;
    opacity: 1 !important;
    font-family: Source Sans Pro;
  }

  .divider {
    height: 2px;
    background-color: #7eb8a2;
    margin-top: 26px;
    margin-bottom: 23px;
  }

  .mat-stepper-vertical, .mat-stepper-horizontal {
      font-family: Source Sans Pro;
  }

  .mat-horizontal-stepper-header-container {
      background: #5d445f !important;
      padding: 0 76px;
      position: sticky;
      z-index: 2;
      top: 0;
  }

  .mat-step-icon-content {
    color: rgba(255, 255, 255, 0.5) !important;
   }

   .mat-horizontal-stepper-header[aria-selected ="true"] .mat-step-icon-content {
    color: white !important;
   }

  .mat-horizontal-stepper-header .mat-step-icon-selected, .mat-horizontal-stepper-header[aria-selected ="true"] .mat-step-icon{
      color: white !important;
      background: #988199 !important;
      border: none !important;
  }

  .completeStepper .mat-horizontal-stepper-header .mat-step-icon-selected,  .completeStepper .mat-horizontal-stepper-header[aria-selected ="true"] .mat-step-icon{
    background: #b4d8ca !important;
    color: white !important;
    opacity: 1 !important;
    border: none !important;
}

  .mat-step-label {
      font-size: 18px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.25) !important;
      //opacity: 0.5;
  }

.mat-step-label-active {
    color: white !important;
    opacity: 1;
  }

  .mat-step-icon-state-done.mat-step-icon {
      background: #b4d8ca !important;
      color: white !important;
      opacity: 1 !important;
      border: none !important;
  }

  .mat-step-icon {
    font-size: 18px;
    font-weight: bold;
    height: 31px !important;
    width: 31px !important;
    border: solid 2px rgba(255,255,255,0.25) !important;
    background-color: rgba(0,0,0,0) !important;
  }


  .mat-step-icon .mat-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
}

.mat-step-icon-selected .mat-step-icon-content, .mat-step-icon-state-done .mat-step-icon-content{
    color: white !important;
}

.mat-stepper-horizontal-line {
    margin: 0;
    mix-blend-mode: soft-light;
    border: dashed 1px #ffffff;
    border-top-style: dashed !important;
}

.mat-horizontal-content-container {
    padding: 0 !important;
}

// mat-step-header[ng-reflect-optional="true"] {
//     pointer-events: none;
//     background-color: transparent !important;
//     display: none;
// }

.h-100 {
    height: 100%;
}


.checkbox-item, .radio-item  {
    --ripple-color: transparent !important;
    --background-hover-opacity: 0 !important;
    padding-left: 4px !important;
}

.checkbox-item{
  padding-top: 7px!important;
  padding-bottom: 6px!important;
}

.checkbox-item ion-label, .radio-item ion-label {
    --color: 282828;
    font-size: 16px !important;
    font-weight: 600;
    color: #282828 !important;
}

//custom-select

.input-group select {
    width: 100%;
    min-height: 47px;
    border: none;
    outline: none;
    padding-left: 8px;
    // appearance: none;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    cursor: pointer;
    font-size: 14px;
}

.input-group .select-icon {
    position: absolute;
    right: 8px;
    cursor: pointer;
    ion-icon {
        font-size: 30px;
        margin-top: 10px;
    }
}

.input-group select:invalid {
    color: #8e8e8c;
    font-style: italic;
}


.custom-select {
    width: 100% !important;
    outline: none !important;
    .select2-container {
        width: 100% !important;
    }
    .select2-container--default .select2-selection--single {
        background-color: #fff;
        height: 47px;
        outline: none !important;
    }

    .select2-selection__rendered {
        line-height: 47px;
        font-size: 14px;
        outline: none !important;
        b {
            display: none;
        }
    }
}

// .select2-search__field {
//     height: 47px;
//     width: 100%;
//     border: none;
//     border-bottom: 3px solid #dfdfdf;
//     outline: none !important;
//     border-radius: 0;
//     padding: 5px;
//     font-size: 14px;
//     font-family: Source Sans Pro;

// }

// .select2-results__options {
//     padding-left: 0;
//     margin: 0;
//     max-height: 150px;
//     overflow-y: auto;
// }

// .select2-results__option {
//     padding-left: 20px;
//     display: block;
//     font-size: 14px;
//     font-family: Source Sans Pro !important;
//     cursor: pointer !important;
//     line-height: 2.71;
//     color: #4d4d4d;
//     b {
//         display: none;
//     }
// }

// .select2-results__option:hover {
//     background: #eeeeee !important;
// }

// .select2-selection__placeholder {
//     font-size: 14px;
//     font-style: italic !important;
//     color: #8e8e8c !important;
// }

// .select2-container .select2-selection--single .select2-selection__rendered {
//     display: block;
//     padding-left: 8px;
//     padding-right: 20px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     --background: none;
//     background-image: url('/assets/dropdown-icon.svg');
//     background-position: top 14px right 6px;
//     background-repeat: no-repeat;
//     background-size: 24px 24px;
// }

// .select2-container .select2-selection--single {
//     box-sizing: border-box;
//     cursor: pointer;
//     height: 28px;
//     user-select: none;
//     -webkit-user-select: none;
// }
// .select2-container {
//     box-sizing: border-box;
//     display: inline-block;
//     margin: 0;
//     position: relative;
//     vertical-align: middle;
// }
// .select2-hidden-accessible {
//     border: 0 !important;
//     clip: rect(0 0 0 0) !important;
//     -webkit-clip-path: inset(50%) !important;
//     clip-path: inset(50%) !important;
//     height: 1px !important;
//     overflow: hidden !important;
//     padding: 0 !important;
//     position: absolute !important;
//     width: 1px !important;
//     white-space: nowrap !important;
// }
// .select2-search--dropdown {
//     display: block;
//     padding: 4px;
// }

// .select2-search--hide {
//     display: none !important;
// }

// .select2-dropdown {
//     background: #d6d3d3;
//     border: none;
//     display: block;
//     position: absolute;
//     z-index: 1051;
//     padding: 0px;
// }

// .select2-container--open .select2-dropdown {
//     left: 0;
// }
// .select2-container--open .select2-dropdown--below {
//     border-top: none;
//     border-top-left-radius: 0;
//     border-top-right-radius: 0;
// }
// .select2-container--open .select2-dropdown--above {
//     border-top: none;
//     border-top-left-radius: 0;
//     border-top-right-radius: 0;
// }
// .select2-container {
//     box-sizing: border-box;
//     display: inline-block;
//     margin: 0;
//     position: relative;
//     vertical-align: middle;
// }

.radio-item ion-radio {
    margin-right: 10px;
    height: 25px;
    width: 25px;
    --color-checked: #023970;
}

.checkbox-item ion-checkbox {
    margin-right: 10px;
    --border-color: white;
    --border-color-checked: white;
    --background-checked: white;
    --background: white;
    --checkmark-color: #023970;
    --checkmark-width: 5px;
    --size: 25px;
    box-shadow: 0px 3px 0 0 #dfdfdf;
}

.navigation-bar {
    padding: 22px 20px;
}

.body-container {
    padding: 15px 85px;
}

.checklist-item {
    padding: 22px 0;
}

.border-top-green {
    border-top: 5px solid #8bb7a6
}

.border-bottom-green {
    border-bottom: 5px solid #8bb7a6
}

.main-content {
    --padding-start: 75px !important;
}

.no-side-nav {
    --padding-start: 0 !important;
}

.d-flex {
    display: flex;
}

.border-bottom-light-green {
    border-bottom: 4px solid #89b8bf;
}

.border-bottom-light-grey {
    border-bottom: 4px solid #dfdfdf;
}

.menu-card-icon img {
    max-height: 75px;
}

.bg-light-blue .menu-card-icon .large-icon{
    font-size: 75px;
    opacity: 0.75;
    mix-blend-mode: soft-light;
}

.bg-green-dashboard .menu-card-icon .large-icon{
    font-size: 75px;
    opacity: 0.75;
    mix-blend-mode: soft-light;
}

.bg-light-grey .menu-card-icon .large-icon{
    font-size: 75px;
    opacity: 0.25;
    color: #2b2925;
}
.menu-title {
    font-size: 18px;
    font-weight: bold;
}

.menu-card.card-content{
    padding: 33px 0px 31px 29px ;
}

.quickScanDownloadButton{
    position: relative;
    margin-top: -7px;
}

.quickscanHeader{
    margin-bottom: 40px;
}

.quickscanResultRow{
    padding-bottom: 63px !important;
}

.quickscan-menu-card {
    .card-content {
        padding: 0;
        padding-left: 30px;
    }
    .card-title {
        padding-top: 23px;
        margin-right: 15px;
        ion-col {
            padding-bottom: 5px !important;
        }
        span {
            font-size: 20px;
            padding-bottom: 5px !important;
        }
    }
    .book-icon {
        padding: 15px;
        font-size: 28px;
        cursor: pointer;
    }

    .book-icon-nohelptext{
        padding: 15px;
        font-size: 28px;

    }

}

.documentLibrary-menu-card {
    .card-content {
        padding: 10px 0 30px 30px;
    }
}
.bg-green.menu-card .card-content {
    padding: 11px 0px 18px 30px;
}

.bg-light-grey .menu-title {
    color: rgba(43, 41, 37, 0.75);
}

.bg-black.menu-card .card-content {
    padding: 32px 30px;
}

.bg-light-blue.menu-card .card-content {
    padding: 32px 30px;
}

.bg-light-grey.menu-card .card-content {
    padding: 32px 20px 20px 30px;
}

.bg-green-dashboard.menu-card .card-content {
    padding: 32px  30px;
}

.bg-light-grey .menu-card-icon {
    padding-bottom: 73px;
}

.bg-light-blue .menu-card-icon {
    padding-bottom: 88px;
}

.bg-green-dashboard .menu-card-icon {
    padding-bottom: 88px;
}

.bs-light-green {
    box-shadow: inset 0px -4px 0 0 #9dc7b7;
}

.popover-arrow.sc-ion-popover-ios{
    --background: #b4d8ca !important;
    margin-left:13px !important
}

.faq-card-content{
    padding-left: 30px;
}


.sub-menu .card-content {
    padding: 43px 40px 34px 30px;
}

.sub-menu .medium-icon {
    font-size: 50px;
    color: #434343;
    opacity: 0.65;
}

.sub-menu .sub-menu-title {
    padding-left: 24px !important;
}

.mat-drawer-container {
    font-family: Source Sans Pro !important;
    height: 100%;
}

.mat-drawer {
    top:92px !important;
}

.menu-text {
    padding-left: 26px !important;
    padding-right: 40px;
    font-size: 20px !important;
    font-weight: bold;
    max-width: 290px !important;
    color: white;
}

.menuItem.active .menu-text {
    color: #2b2925;
}

.welcome-msg {
    margin-top: 30px !important;
    ion-icon {
        font-size: 41px;
        color: #dfdfdf;
    }
    ion-label {
        font-size: 26px !important;
        margin-left: 5px;
        color: rgba(43, 41, 37, 0.75);
    }
}

.need-help {
    padding-left: 45px !important;
    padding-top: 36px;
    justify-content: space-between;
}

.need-help-container {
    padding-right: 100px;
}

.card-title {
    margin-bottom: 14px;
    ion-col {
        border-bottom: 3px solid #d8d8d8;
        padding-bottom: 10px !important;
    }
    span {
        border-bottom: 3px solid #ffcc46;
        padding-bottom: 10px !important;
        font-size: 24px;
        font-weight: bold;
        color: #2b2925;
    }
}

.knowledgeBase-card-title {
     span {
        border-bottom: 3px solid #ffcc46;
        padding-bottom: 10px !important;
        font-size: 24px;
        font-weight: bold;
        color: #2b2925;
    }
}
.documentlibrary-card-title {
    margin-top: 23px;
    margin-bottom: 14px;
    ion-col {
        border-bottom: 3px solid #d8d8d8;
        padding-bottom: 10px !important;
    }
    span {
        border-bottom: 3px solid #ffcc46;
        padding-bottom: 10px !important;
        font-size: 24px;
        font-weight: bold;
        color: #2b2925;
    }
}
.m-15 {
    margin: 15px !important;
}

.mh-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mv-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.detail-card {
    margin: 38px 100px;
    margin-top: 0;
}

.invoice-data-table{
    margin: 0px;
}

//custom-select
.input-group select {
    width: 100%;
    min-height: 47px;
    border: none;
    outline: none;
    padding-left: 8px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 14px;
}

.input-group .select-icon {
    position: absolute;
    right: 8px;
    cursor: pointer;
    ion-icon {
        font-size: 30px;
        margin-top: 10px;
    }
}

// .ngSelect-group .select-icon {
//     position: absolute;
//     right: 8px;
//     cursor: pointer;
//     ion-icon {
//         font-size: 30px;
//         margin-top: 10px;
//     }
// }

.input-group select:invalid {
    color: #8e8e8c;
    font-style: italic;
}

.badge {
    position: absolute;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    background: #023970;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    top: 22px;
    left: 47px;
    border: solid 2px #F8F8F8;
}



.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #F8F8F8 !important;
    transition-property: background;
    transition-duration: .3s;
    transition-timing-function: linear;
}

.no-padding-bottom .header-container {
    padding-bottom: 8px !important;
}

.date-time-control ion-icon {
    font-size: 24px;
    color: var(--ion-color-primary);
    cursor: pointer;
    position: absolute;
    right: 5px;
    padding: 10px;
}

.dateTimeControl {
    border: none;
    height: 45px;
    width: 100%;
    margin: 0 0 3px 5px;
    font-size: 14px;
}

.dateTimeControl:focus {
    border: none;
    outline: none;
}

//mat-select -- Amar
.mat-mdc-select{
    height:45px;
    display: flex;
    align-items: center;
}

.mat-mdc-select-placeholder {
    font-size: 14px;
    font-style: italic !important;
    color: #8e8e8c !important;
    opacity: 1 !important;
    font-family: Source Sans Pro;
    line-height: 47px;
    padding-left: 0 !important;
}

.mat-mdc-select-search-input {
    width: 100%;
    box-shadow: inset 0 -3px 0 0 #dfdfdf;
    border: none !important;
    height: 47px !important;
    outline: none !important;
}

.mat-mdc-select-arrow {
    width: 0 !important;
    border: none !important;
}

.mat-select-trigger {
    background-image: url('/assets/dropdown-icon.svg');
    background-position: top 14px right 6px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    display: inherit !important;
}

.templateFilter{
    .mat-select-trigger {
        background-image: url('/assets/icons/icon_filter_blue.svg') !important;
        background-position: top 14px right 6px !important;
        background-repeat: no-repeat !important;
        background-size: 24px 24px !important;
    }
}

.templateSorting{
    .mat-select-trigger {
        background-image: url('/assets/icons/icon_sort_down_blue.svg') !important;
        background-position: top 14px right 6px !important;
        background-repeat: no-repeat !important;
        background-size: 24px 24px !important;
    }
}

.mat-option-ripple {
    display: none;
}

.mdc-list-item__primary-text{
  display: flex !important;
  align-items: center !important;
}

.mat-mdc-select-panel {
    background: #eeeeee;
    //transform: none !important;
    //transform-origin: bottom !important;
    font-size: 14px !important;
    min-width: 100% !important;
    border-radius: 0 !important;
    margin-top: -46px!important;
    // padding: 0px!important;

    //max-height: 200px !important;
}

.mat-mdc-select-value {
    line-height: 47px;
    padding-left: 16px;
    font-size: 14px;
    padding-right: 20px;
    width: 95% !important;
}

.cdk-overlay-pane {
    transform: none !important;
}

.mat-mdc-option.mat-mdc-active {
    background: transparent;

}

// .mat-mdc-option.mdc-list-item.mdc-list-item--selected.mat-mdc-option-active.ng-tns-c99-4.ng-star-inserted{
//   font-size: 14px !important;
//   min-height: 30px !important;
// }

mat-option{
  font-size: 14px !important;
  min-height: 42px !important;
  display: flex !important;
  align-items: center !important;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: transparent!important;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: transparent !important;

}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
            // color: #023970;
            background: #023970!important;
            // --background: blue!important;
          }

          .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
            // color: transparent !important;
            // background: #023970;

          }

          .mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text, .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
            color: black!important;

          }

.mat-mdc-option-text {
    display: flex !important;
    align-items: center;
}

.img-flag {
    height: 20px;
    width: 20px;
    margin-top: -3px;
    margin-right: 5px;
}

.text-readonly:disabled, .mat-select-disabled, .text-readonly > .native-input[disabled] {
    background: #f9f7f7 !important;
}

.segment-button-checked {
    background: rgb(93, 68, 95);
    color: white;
    ion-icon {
        color: white;
    }
    ion-label {
        color: white;
    }
}

ion-segment-button {
    --background-hover-opacity: 0;
}

ion-segment {
    background: rgba(93, 68, 95, 0.1);
}

.tooltip {
    background-color: #4c4a4a;
    border: 0;
    border-radius: 5px !important;
    padding: 5px 8px !important;
    color: white;
    font-weight: 600;
    font-size: 13px;
    box-shadow: none !important;
    border: none !important;
    z-index: 9999 !important;
}

.popperSpecialStyle{
    background-color: #4c4a4a;
    border: 0;
    border-radius: 5px !important;
    padding: 5px 8px !important;
    color: white;
    font-weight: 600;
    font-size: 13px;
    box-shadow: none !important;
    border: none !important;
    z-index: 9999 !important;
    margin-top: 74px;
}

.popperSpecialStyleFull{
    background-color: #4c4a4a;
    border: 0;
    border-radius: 5px !important;
    padding: 5px 8px !important;
    color: white;
    font-weight: 600;
    font-size: 13px;
    box-shadow: none !important;
    border: none !important;
    z-index: 9999 !important;
    margin-top: -14px !important;
}

.popperSpecialStyleHome{
    background-color: #4c4a4a;
    border: 0;
    border-radius: 5px !important;
    padding: 5px 8px !important;
    color: white;
    font-weight: 600;
    font-size: 13px;
    box-shadow: none !important;
    border: none !important;
    z-index: 9999 !important;
    margin-top: 59px !important;
}

.popperSpecialStyleHome>.ngxp__arrow{
    transform: rotate(270deg) !important;
    margin-top: -7px !important;
    left: 23px !important;
    right: '' !important;
}

.popperSpecialStyle>.ngxp__arrow{
    transform: rotate(270deg) !important;
    margin-top: -7px !important;
    left: 23px !important;
    right: '' !important;
}

.pointerCursor {
    cursor: pointer;
}

.ionic-selectable-modal.ionic-selectable-modal-md{
    top: 0px !important;
}

.ionic-selectable-modal.ionic-selectable-modal-md .header-md::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==) !important;
}



.ion-page {
    top: 92px !important;
}

.app-page {
    top: 0 !important;
}

.header-md::after {
    background-image: none !important;
}

.modal-lg .modal-wrapper {
    --min-width: 80%;
    --min-height: 80%;
}

.modal-lg .ion-page {
    top: 0 !important;
    background: #F8F8F8;
}

.modal-lg .header-container {
    padding: 20px;
    padding-bottom: 5px;
}

.modal-lg .detail-card {
    margin: 20px !important;
    margin-top: 0 !important;
}

.knowledgebase-title {
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
}

.kb-title {
    margin-bottom: 20px;
}

.kb-title span{
    border-bottom: 3px solid #e3e0e0;
    padding-bottom: 5px !important;
    font-size: 24px;
    font-weight: bold;
    color: #2b2925;
}

.kb-sidenav {
   width: 551px;
   background: #eeeeee;
   box-shadow: -8px 2px 10px -5px rgba(0, 0, 0, 0.12) !important;
}

.kb-sidenav .mat-drawer-inner-container{
    overflow: hidden !important;
}

.kb-menu {
    background: #e0e0e0;
    .border-bottom {
        border-bottom: 4px solid #c1c1c1;
    }
    .card-content {
        padding: 0;
    }
    .list-item {
        color: #6A6A6A;
    }
    .kb-item {
        --background: #e0e0e0;
        --inner-padding-start: 28px;
        --inner-padding-end: 8px;
        --min-height: 60px;
        --border-width: 0 0 2px;
        --border-color: #ccc9c9;
        cursor: pointer;
    }
}

.kb-showMore {
    width: 100%;
    margin: 20px 28px !important;
}

.kb-container {
    padding-bottom: 30px;
}

.kb-viewAll {
    align-items: center;
    font-style: italic;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
}
.kb-backdrop {
    background-color: transparent;
    //background-color: #c61919;
    top: 92px;
    left: 0;
    right: 96px;
    bottom: 0;
    position: absolute;
    display: block;
    z-index: 114;
}

.kb-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
    z-index: 114;
}

.kb-loading-wrapper {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    padding: 24px;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
}

.note-modal-backdrop {
    display: none !important;
}

.note-modal-footer {
    height: 70px !important;
}

.note-modal-footer .note-btn {
    height: 52px;
    background: #023970;
    border-radius: 7.5px;
    font-size: 16px;
    font-weight: bold;
    border: none;
}
.note-btn-primary:hover {
    border: none !important;
    background-color: #255d94 !important;
    border-radius: 7.5px !important;
}

.kb-detail p {
    margin: 0 !important;
}

.video-container {
    margin-top: 20px;
}

.kb-detail{
    iframe, video {
        max-width: 100%;
    }
}

.ViewerPDF .ng2-pdf-viewer-container{
    overflow-x: hidden !important;
    // position: absolute !important;
    // height: 500px !important;
    // -webkit-overflow-scrolling: touch !important;
}
.ViewerPDF .ng2-pdf-viewer-container  .page{
    // width: 700px !important;
}

app-pdfviewer.ion-page {
    top: 0px !important;
}

.pdfCss{
    --max-height: 700px !important;
    --height: 554px !important;
    --width: 700px !important
}

table{
    width: -webkit-fill-available !important;
}

.note-editor.note-frame.codeview .note-editing-area .note-codable{
    background-color: white !important
}

.knowlwdgeBase-link-cointainer a{
    color:blue !important;
}

.note-modal-content {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    z-index: 99999997;
    background: #eeeeee !important;
}

.note-modal-header{
    border-bottom: 1px solid darkgray !important;
}

.note-input{
    border: 1px solid #aaa !important;
    border-radius: 4px !important;
}


.collapsedBar{
    width: 519px;
}

.expandBar{
    width: 1038px;
}

.knowledgeBaseBorder{
    border: 10px solid white;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
}

.modal-backdrop {
    z-index: -1;
  }

  .processingRegister-table  .datatable-body{
    height: calc(100vh - 414px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.overlay-hidden.sc-ion-loading-md-h{
    display: flex !important;
}

.overlay-hidden.sc-ion-alert-md-h{
    display: flex !important;
}

.quickscanInput{
    background-color: #fff !important;
}

ion-input.form-control {
  box-shadow: inset 0 -3px #dfdfdf;
}

// ion-checkbox::part(container){
// background: white;
//   }

// .pdfViewer.removePageBorders .page {
//      margin: 0 auto 0px !important

// }

// @media only screen and (min-width: 1000px) {
//     .ViewerPDF .ng2-pdf-viewer-container{
//         overflow-x: hidden !important;
//         position: relative !important;
//         height: 500px !important;
//         -webkit-overflow-scrolling: touch !important;
//     }
//     .ViewerPDF .ng2-pdf-viewer-container  .page{
//         width: 700px !important;
//     }


//     .pdfCss{
//         --max-height: 700px !important;
//         --height: 554px !important;
//         --width: 700px !important
//     }

//     .pdfViewer.removePageBorders .page {
//          margin: 0 auto 0px !important

//     }

// }


.popUpGender{
  min-width: fit-content;
}

.mat-select-search-input {
  background-color: white !important;
  background: white!important;
}

// .input-wrapper.sc-ion-input-md{
//   padding-top: 0px!important;
// }

// .item-label-stacked.sc-ion-input-md-h, .item-label-stacked .sc-ion-input-md-h, .item-label-floating.sc-ion-input-md-h, .item-label-floating .sc-ion-input-md-h {
// --padding-bottom: 20px!important;
// }

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 47px!important;
  background: var(--background);
}

ion-radio::part(container){
  height: 25px;
  width: 25px;
}


.ngx-modal-askQuestionModal.nsm-dialog {
    position: fixed !important; /* Ensures it stays fixed in place */
    right: 20px; /* Distance from the right edge */
    bottom: -28px; /* Distance from the bottom edge */
    top: auto !important; /* Prevents vertical shifts */
    left: auto !important; /* Prevents horizontal shifts */
    transform: none !important;
}

.ngx-modal-askQuestionModal .nsm-content{
    max-height: 602px !important;
    overflow: hidden;
    padding: 0 !important;

    --background: #eeeeee;
    background: #eeeeee;

    ion-textarea textarea{
        height: 120px !important;
    }
}

.ngx-modal-askQuestionModal .nsm-content ion-grid ion-row.ion-row-scroll{
    overflow-y: scroll;
    margin: 0.5rem 1rem 0.5rem 1rem;
    max-height: 416px !important;
}